<template>
    <div class="view">
        <router-view
            :loading="loading"
            :local-data="localSpaceData"
            :space-fields="spaceFields"
            name="space_content"
            ref="space-content"
            @data-updated="updateLocalData"
        />
    </div>
</template>

<script>
    import { mapMutations, mapGetters, mapState, mapActions } from "vuex";

    export default {
        name: "SpaceMain",
        computed: {
            ...mapState("helper", ["features"]),
            ...mapGetters("helper", ["labels"]),
            pageHeaderData() {
                let btnList = [
                    {
                        type: "label",
                        link: { name: "space-single", params: { id: this.$route.params.id } },
                        label: this.labels.editor,
                        class: `btn-type-label ${
                            this.$route.name === "space-single" ? "current" : ""
                        }`
                    },
                    {
                        type: "label",
                        link: { name: "space-blocks", params: { id: this.$route.params.id } },
                        label: this.labels.blocks,
                        class: `btn-type-label ${
                            this.$route.name === "space-blocks" ? "current" : ""
                        }`
                    },
                    {
                        type: "label",
                        link: { name: "space-authors", params: { id: this.$route.params.id } },
                        label: this.labels.authors,
                        class: `btn-type-label ${
                            this.$route.name === "space-authors" ? "current" : ""
                        }`
                    },
                    {
                        type: "label",
                        link: { name: "space-reviews", params: { id: this.$route.params.id } },
                        label: this.labels.reviews,
                        class: `btn-type-label ${
                            this.$route.name === "space-reviews" ? "current" : ""
                        }`
                    },
                    {
                        type: "label",
                        link: { name: "space-owners", params: { id: this.$route.params.id } },
                        label: this.labels.owners,
                        class: `btn-type-label ${
                            this.$route.name === "space-owners" ? "current" : ""
                        }`
                    },
                    {
                        type: "label",
                        link: { name: "space-css", params: { id: this.$route.params.id } },
                        label: this.labels.sass_css.toUpperCase(),
                        class: `btn-type-label ${
                            this.$route.name === "space-css" ? "current" : ""
                        }`
                    },
                    {
                        type: "button",
                        label: this.labels.save,
                        variant: "btn-green",
                        class: "btn-type-button",
                        action: this.saveAction,
                        disabled: false,
                    }
                ];

                const output = {
                    preTitle: this.labels.space,
                    title: this.localSpaceData.title?.length ? this.localSpaceData.title : '',
                    btnList: btnList,
                };

                return output;
            },
            spaceFields() {
                return {
                    logo: {
                        kind: 'image',
                        label: this.labels.logo
                    },
                    background: {
                        kind: 'image',
                        label: this.labels.background_image
                    },
                    slug: {
                        kind: 'text',
                        label: this.labels.slug
                    },
                    title: {
                        kind: 'text',
                        label: this.labels.title
                    },
                    customStyle: {
                        kind: 'code',
                        label: this.labels.sass_css.toUpperCase()
                    },
                    excerpt: {
                        kind: 'textarea',
                        label: this.labels.excerpt
                    },
                    description: {
                        kind: 'richtext',
                        label: this.labels.description
                    },
                    faqList: {
                        kind: 'list',
                        label: this.labels.faq,
                        structure: {
                            title: {
                                kind: 'text',
                                label: this.labels.title
                            },
                            answer: {
                                kind: 'richtext',
                                label: this.labels.answer
                            }
                        }
                    },
                    authors: {
                        kind: 'list',
                        label: this.labels.authors,
                        structure: {
                            photo: {
                                kind: 'image',
                                label: this.labels.photo
                            },
                            name: {
                                kind: 'text',
                                label: this.labels.name
                            },
                            description: {
                                kind: 'richtext',
                                label: this.labels.description
                            }
                        }
                    },
                    reviews: {
                        kind: 'list',
                        label: this.labels.reviews,
                        structure: {
                            title: {
                                kind: 'text',
                                label: this.labels.title
                            },
                            name: {
                                kind: 'text',
                                label: this.labels.name
                            },
                            description: {
                                kind: 'richtext',
                                label: this.labels.description
                            }
                        }
                    },
                    body: {
                        kind: 'object',
                        label: this.labels.blocks
                    }
                }
            }
        },
        data() {
            return {
                loading: true,
                localSpaceData: {}
            }
        },
        methods: {
            ...mapActions('notification', ['addNotification']),
            ...mapMutations("helper", ["setPageHeaderData"]),
            ...mapActions("space", ["getSingle", "updateSpace"]),
            saveAction() {
                this.loading = true;

                this.updateSpace(this.$prepareForm({ id: this.$route.params.id, ...this.localSpaceData})).then( res => {
                    if (res.error) {
                        this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
                    }

                    this.loading = false;
                } ).catch( err => console.log( err ) );
            },
            checkIfActiveBtn(kind) {
                console.log(kind);
                // if (
                //     this.$route.name === "mb-settings-advanced" &&
                //     this.$route.params.kind === kind
                // ) {
                //     return "current";
                // }
                return "";
            },
            getSpaceData() {
                this.loading = true;

                this.getSingle( { id: this.$route.params.id } ).then( res => {
                    if (res.success) {
                        Object.keys(this.spaceFields).forEach(fieldName => {
                            if (res.data[fieldName]) this.$set(this.localSpaceData, fieldName, res.data[fieldName]);
                        });
                    }

                    if (res.error) {
                        this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
                    }

                    this.loading = false;
                } ).catch( err => console.log( err ) );
            },
            init() {
                this.setPageHeaderData(this.pageHeaderData);

                for (const [fieldName, field] of Object.entries(this.spaceFields)) {
                    const value = this.$defaultValue(field.kind);

                    this.$set(this.localSpaceData, fieldName, value);
                }

                this.getSpaceData();
            },
            updateLocalData(name, value) {
                this.$set(this.localSpaceData, name, value);
            }
        },
        beforeMount() {
            this.setPageHeaderData({ preTitle: "", title: "", btnList: [] });
        },
        mounted() {
            this.init();
        },
        beforeDestroy() {
            this.setPageHeaderData({});
        },
        watch: {
            pageHeaderData(value) {
                this.setPageHeaderData(value);
            }
        }
    };
</script>

<style lang="scss" scoped></style>
